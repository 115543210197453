@import '../../../styles/customMediaQueries.css';
@import '../../../styles/gridDefaults.scss';

.root {
}

.avatar {
  flex-shrink: 0;
  margin-right: 36px;
}

.heading {
  composes: p from global;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: inline;

    &::before {
      content: ' ';
    }
  }
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.links {
  margin-top: 13px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  composes: p from global;
  display: none;
  // padding: 0;
  margin-top: 0;
  // white-space: nowrap;
  float: right;

  @media (--viewportMedium) {
    display: block;
  }
}

.editProfileMobile {
  composes: p from global;
  display: flex;
  float: left;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin: 0;
  }
}

.displayName {
  line-height: 1.5em;
  font-size: 24px;
  font-weight: var(--fontWeightSemiBold);

  @media (--viewportMaxSmall) {
    font-size: 20px;
  }
}

.memberSinceText {
  line-height: 2.5em;
}

.reviewSection {
  .rating {
    display: inline-flex;
    white-space: nowrap;

    .star {
      height: 13px;
      width: 13px;
      margin-right: 3px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .averageValue {
      font-weight: var(--fontWeightMedium);
      font-size: var(--fontSizeMedium);
      margin-left: 8px;
      margin-top: -5px;
    }
  }
}

.colUserInfo {
  flex: $flexColDefault;
  max-width: 65%;

  @media (--viewportMedium) {
    width: 65%;
  }
}

.colEditProfile {
  flex: $flexColDefault;
  display: none;
  width: 15%;

  @media (--viewportMedium) {
    display: block;
  }
}

.iconVerified {
  width: 17px;
  margin-bottom: 8px;
}
